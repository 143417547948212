
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "base-layout",
})
export default class BaseLayout extends Vue {
  mounted() {
    document.body.classList.remove("layout-page-with-map");
    document.body.classList.add("layout-base");
  }
}
